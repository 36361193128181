import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { inject, observer } from 'mobx-react';

import { Layout } from '../components/Layout/Layout';
import { Carousel } from '../components/Carousel/Carousel';
import { ImagePreview } from '../components/ImagePreview/ImagePreview';
import { Head } from '../components/Head/Head';

export const IndexPageTemplate = () => {
  return <></>;
};

const IndexPage = inject('store')(observer(({data, store}) => {
  const { frontmatter } = data.markdownRemark
  store.setPaintings(frontmatter.images);

  return (
    <>
      <Head title="Katya Bessmertnaya Paintings" description="Paintings and Graphics by Katya Bessmertnaya" />
      <Layout>
        <Carousel images={frontmatter.images} />
      </Layout>
      <ImagePreview />
    </>
  );
}));

IndexPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object,
    }),
  }),
};

export default IndexPage

export const pageQuery = graphql`
  query IndexPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "index-page" } }) {
      frontmatter {
        title
        images {
          src {
            childImageSharp {
              fluid(maxWidth: 1080, quality: 80, jpegProgressive: true, sizes: "(max-width: 320px and max-device-pixel-ratio: 5) 480px, (max-width: 740px and max-device-pixel-ratio: 5) 960px, (max-width: 980px and max-device-pixel-ratio: 5) 960px, 1080px") {
                ...GatsbyImageSharpFluid
              }
            }
            id
          }
          title
          size {
            width
            height
          }
          materials
          year
        }
      }
    }
  }
`;
